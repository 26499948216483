import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"

class Training extends React.Component {
  componentDidMount = () => {
    window.location.href='https://www.hookpointcall.com/masterclass-registration'
  }

  render() {
    return (
      <Layout>
        <SEO title="Training" />
      </Layout>
    )
  }
}

export default Training
